/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          document.body.classList.add('js-loading');

          function showPage() {
            document.body.classList.remove('js-loading');
            document.body.classList.add('images-loaded');
          }

          window.addEventListener("load", showPage);

        /*============================================================================
          Menu toggle
        ==============================================================================*/

        function toggleNav() {
          var menuToggle = ('.menu-toggle');
          var banner = ('.banner');

          $(menuToggle).click(function() {
            if ( $(banner).hasClass('nav-toggled') ) {
              $(banner).removeClass('nav-toggled');
              $('html').removeClass('u-no-scroll');
            } else {
              $(banner).addClass('nav-toggled');
              $('html').addClass('u-no-scroll');
              $(this).focus();
            }
          });

        }
        toggleNav();

         /*============================================================================
          #scrollTo Animation
        ==============================================================================*/

        /* https://css-tricks.com/snippets/jquery/smooth-scrolling/ */
        function scrollToAnimation() {
          $('a[href*="#"]:not([href="#"])').on('click', function() {
            if ( location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') &&
             location.hostname === this.hostname &&
             !$(this).hasClass("no-move") ) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top - 0
                }, 700);
              }
                return false;
            }
          });
        }
        scrollToAnimation();

        /*============================================================================
          # Animations + Waypoints
        ==============================================================================*/

        function waypoints() {
          // Waypoint.refreshAll();
          var animate = $('.anim').waypoint({
            handler: function(direction) {
              // console.log('anim');
              if (direction === 'down'){
                $(this.element).addClass('go');
              } else {
                $(this.element).removeClass('go');
              }
            },
            offset: '100%'
          });
        }
        waypoints();

        //modal

        function imageModal() {

              var $close_modal = $('.close-modal'),
                  $open_modal = $('.open-modal'),
                  $modal_content = $('#modal-content');

              var clicked_el = '',
                  $body = $('html'),
                  $getNext = $('.right-arrow'),
                  $getPrev = $('.left-arrow'),
                  imgSrcs = [];

              for ( i = 0; i < $open_modal.length; i++ ) {
                imgSrcs.push($open_modal[i]);

                $(imgSrcs[i]).on('click', function() {
                  // console.log('image clicked');

                    var allMods = $(".anim");
                    var allLazy = $(".lazy");

                    allMods.each(function(i, el) {
                      var el = $(el);
                      var imgSrc = el.find('img');

                        imgSrc.attr({
                          src: imgSrc.attr('data-src')
                        }).removeAttr('data-src');

                    });

                    allLazy.each(function(i, el) {
                      var el = $(el);
                        el.attr({
                          src: el.attr('data-src')
                        }).removeAttr('data-src');
                    });

                  clicked_el = this;

                  var image_href = this.src,
                      imgPosition = imgSrcs.indexOf(this);

                  $modal_content.html('<img class="slideshow" src="' + image_href + '" />');
                  $body.addClass('u-no-scroll has-modal');
                  $close_modal.focus();

                  var myArray = imgSrcs;
                  // console.log(myArray);
                  var totalImages = myArray.length;
                  var myIndex = imgPosition;
                  var print = $('#modal-content');

                    $getNext.on('click', function() {
                      myIndex = (myIndex+1) % totalImages;
                      print.html('<img class="slideshow" src="' + myArray[myIndex].src + '" />');
                    });

                    $getPrev.on('click', function() {
                      myIndex = (myIndex-1) % totalImages;
                      if (myIndex == -1 ) { myIndex = totalImages-1;}
                      print.html('<img class="slideshow" src="' + myArray[myIndex].src + '" />');
                    });

                });
              }

              $close_modal.on('click', function() {
                $body.removeClass('u-no-scroll has-modal');
                $(clicked_el).focus();
              });

              $('.modal').on('click', function(e) {
                if(!$(e.target).is('img')) {
                  $body.removeClass('u-no-scroll has-modal');
                  $(clicked_el).focus();
                }

              });

          }
         // imageModal();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        var slideHeight = $(window).height();
          // console.log(slideHeight);
          $('.home').css('height', slideHeight);
          $('.home-slide').css('height', slideHeight);
          $('.home-bg').css('height', slideHeight);
          $('.loading').css('height', slideHeight);
        // JavaScript to be fired on the home page
          function fadeInImages() {
            // console.log('hello');
            var homeSlide = $('.home-slide');
            var leftSlide = $('.home-bg-left');
            var rightSlide = $('.home-bg-right');
            var numberOfSlides = homeSlide.length;
            var maxIndex = (numberOfSlides - 1);
            var slideIndex = 1;
            var rightIndex = 1;

            (function loop() {

              var rand = Math.floor(Math.random() * 1000 + 7000 );
              // console.log(rand);
              setTimeout(function() {
                prevIndex = (slideIndex - 1);
                nextIndex = (slideIndex + 1);

                if (slideIndex === numberOfSlides) {
                  slideIndex = 0;
                  prevIndex = maxIndex;
                  nextIndex = 0;
                }
                console.log(slideIndex);

                $(leftSlide[prevIndex]).animate({
                  opacity: 0,
                }, 2000);
                $(leftSlide[slideIndex]).animate({
                  opacity: 1,
                }, 2000);
                slideIndex++;
                loop();
              }, rand);
            }());

            (function loop() {
              var rightRand = Math.floor(Math.random() * 1000 + 7000 );
              // console.log(rightRand);
              setTimeout(function() {
                prevIndexRight = (rightIndex - 1);
                nextIndexRight = (rightIndex + 1);

                if (rightIndex === numberOfSlides) {
                  rightIndex = 0;
                  prevIndexRight = maxIndex;
                  nextIndexRight = 0;
                }

                console.log(rightIndex);
                $(rightSlide[prevIndexRight]).animate({
                  opacity: 0,
                }, 2000);
                $(rightSlide[rightIndex]).animate({
                  opacity: 1,
                }, 2000);
                rightIndex++;
                loop();
              }, rightRand);
            }());

          }

          fadeInImages();
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
